/*--------------------------------------------------------------
# Host Flow
--------------------------------------------------------------*/
/* .host-sec .flow-content{

}
.host-sec .flow-content div {
    float: left;
    width: 33.3%;
}
.host-sec .flow-content div:last-child {
    float: none;
    width: auto;
    margin-left: 66.6%;
} */

.services-circle{
    position: relative;
    /* padding: 97px 97px 34px; */
    margin: 0px auto;
    padding: 30px 0px;
    --circle-radius: 150px;
    --circle-icon-sz: 40px;
    --circle-icon-img: 24px;
    --circle-icon-sm-img: 20px;
  }
  .services-circle .arrow{
    position: absolute;
    width: 100%;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .services-circle .c-rotate-container{
    width: 300px;
    height: 300px;
  }
  .services-circle .inner-circle .third img{
    /* width: 100px; */
    width: 84px;
  }
  .services-circle .circle-icon{
    background: #f8f9fb;
 }
.host-sec .sub-heading,
.seller-sec .sub-heading{
    width: 42%;
}
.host-sec .title-gap,
.seller-sec .title-gap{
    margin-top: 100px;
}
.consumer-app,
.native-app{
    position: relative;
}
.consumer-app img{
    transform: rotate(-8.09deg);
}
.native-app img{
    /* transform: rotate(8.09deg); */
    transform: rotate(14deg);
}
.flow-content,
.flow2-content{
    margin-top: 55px !important;
}
.flow-content .consumer-app img,
.flow-content .native-app img{
    /* width: 100%; */
    padding: 12px;
    height: 409px;
}
.flow-content .consumer-app img{
    height: 374px;
}
.native-app .circle-sm-bg-pink,
.native-app .circle-sm-bg-blue{
    /* backdrop-filter: blur(1px); */
    backdrop-filter: blur(-1px);
}
/* .host-sec .heading .tagline{
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    color: var(--color-blue);
} */
/*
.flow-con .circle-con p{
    font-size: 20px;
    font-weight: 500;
    height: 100%;
}
.flow-con .circle-con,
.flow-con .circle-con p {
    justify-content: center;
    align-items: center;
    display: flex;
}
.flow-con .circle-con .f-circle{
    height: 116px;
    width: 116px;
    position: relative;
}
.flow-con .circle-con .l-circle{
    height: 98px;
    width: 98px;
    position: absolute;
    top: 11%;
    left: 43%;
    top: 50%;
    left: 50%;
    margin: -49px 0px 0px -49px;
}
.flow-con .circle-con .f-circle,
.flow-con .circle-con .l-circle{
    border-radius: 50%;
    background-color: white;
    border: 2px solid var(--color-blue);
    border-radius: 50%;
}
.flow-con .domain{
    display: flex;
    padding: 0px;
    margin: 0;
}
.flow-con .domain li{
    display: flex;
    font-size: 17px;
    height: 46px;
    border-radius: 78px;
    background-color: white;
    border: 2px solid var(--color-blue);
    margin: 0px 8px;
    align-items: center;
    justify-content: center;
    width: 140px;
    font-family: "Open Sans", sans-serif;;
    font-weight: 600;
}
.flow-con .domain .middle{
    width: 172px;
    color: var(--color-blue);
}
.flow-con .btn-cont{
    background-color: rgba(0, 138, 255, 0.4);
    border-radius: 78px;
    box-shadow: 0 0 76.6px rgba(0, 138, 255, 0.4);
}
.flow-con .main-btn{
    height: 46px;
    width: 194px;
    font-size: 20px;
    border-radius: 78px;
    margin: 6px;
}
.flow-con .domain-icon li{
    border-radius: 10px;
    padding: 8px;
    width: 46px;
}
.flow-con .domain-icon .middle{
    width: auto;
    color: black;
    padding: 0px 18px;
    border-radius: 78px;
} */
 /*--------------------------------------------------------------
# Seller Flow
--------------------------------------------------------------*/
.flow2-content{
    width: 100%;
    text-align: center;
    justify-content: center;
    display: grid;
}
.flow2-content .box{
    border: 1px solid var(--accent-blue);
    border-radius: 8px;
    padding: 8px 12px;
    width: 190px;
    display: flex;
    justify-content: space-between;
    color: var(--accent-blue);
    /* font-size: 15px; */
    font-weight: 400;
    height: 50px;
    margin-bottom: 22px;
    background: white;
}
.flow2-content .box span{
    align-content: center;
}
.flow2-content .box img{
    width: 25px;
}
/*--------------------------------------------------------------
# Oppertunity
--------------------------------------------------------------*/
.opper-sec img{
    height: 60px;
    width: 60px;
    margin-bottom: 15px;
    margin-top: 30px;
}
.opper-sec p{
    margin-bottom: 0px;
    color: var(--color-lightText);
    font-size: 16px;
    margin-top: 10px;
}
.opper-sec h3{
    font-size: 22px;
    width: 75%;
}
.opper-sec .first-opp h3{
    width: 52%;
}
.opper-sec .card{
    padding: 35px 30px 30px 35px;
    /* background: linear-gradient(rgb(243, 249, 252, 1), rgb(252 252 252)) right / 50% 100%, linear-gradient(to bottom, rgb(250 250 250), rgb(255 255 255)); */
    /* background: radial-gradient(ellipse at left bottom, #ffffff 70%, #ffffff 62%, #2d81e026 100%); */
    /* background: radial-gradient(ellipse at left bottom, #ffffff 62%, #ffffff 22%, #2d81e03d 96%); */
    /* box-shadow: -19px 44px 17.2px #2d81e000 inset; */
    min-height: 300px;
    /* z-index: -1; */
    z-index: 1;
    box-shadow: 0 0 0px rgba(0, 0, 0, .08);
}
.opper-sec .card:hover{
    border: 1px solid #a8d1f4 !important;
    /* z-index: 9 !important; */
}
/*
.opper-sec .card:before {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    background: inherit; 
    transform: rotate(-90deg);
    clip-path: polygon(100% 0, 100% 100%, 0 100%);
  } */
.opper-sec .oval-circle-pi{
    border-radius: 50%;
    width: 50%;
    height: 189px;
    background: var(--bg-pink);
    right: 0;
    top: 0;
    transform: translate(90px, -51px);
}
.opper-sec .oval-circle-bl{
    border-radius: 50%;
    width: 50%;
    height: 189px;
    background: var(--bg-blue);
    transform: translate(95px, -121px);
}
.opper-sec .bg-c-gradient{
    filter: blur(100px);
}
/*--------------------------------------------------------------
# SDK Navigate
--------------------------------------------------------------*/
.sdk-con{
    position: relative;
    background: url(../../images/bg/puzzle.svg) 100% center no-repeat;
    /* background: url(../../images/bg/puzzle.svg); */
    /* background-size: contain !important; */
    /* overflow: hidden; */
    position: relative;
    padding: 110px 0px;
    /* background-color: #f1f7ff; */
    /* background-color: white; */
    margin-bottom: 130px;
    /* background-size: 161% 112%; */
    background-position: right top;
    z-index: -2;
}
.sdk-con p{
    font-size: 18px;
    color: var(--color-lightText);
}
.sdk-con .oval-pink{
    top: 0;
    height: 132px;
    width: 30%;
    background: var(--bg-pink);
    /* filter: blur(150px);
    z-index: -1; */
    border-radius: 50%;
}
/*--------------------------------------------------------------
# Idea
--------------------------------------------------------------*/
.idea-con{
    /* background-color: #C2DCFF; */
    background-color: #F0F8FF;
    padding: 60px 0px;
    /* margin-bottom: 130px; */
    border-radius: 20px;
    z-index: -1;
    position: relative;
    overflow: hidden;
    margin-top: 33px;
}
.idea-con button{
    padding: 13px 34px;
    font-weight: 500;
}
.idea-con .oval-circle-bl{
    height: 250px;
    left: 0;
    top: 0;
    transform: translate(-106px, -139px);
}
.idea-con .oval-circle-bl,
.idea-con .oval-circle-blu{
    /* background: var(--bg-blue); */
    background: #8ec2fe;
    border-radius: 50%;
    width: 28%;
}
.idea-con .oval-circle-blu{
    right: 0;
    bottom: 0;
    height: 300px;
    transform: translateX(65px);
}
.consumer-app .sm-circle-pnk{
    background: var(--bg-pink);
    width: 90%;
    height: 250px;
    transform: translateY(50%);
    filter: blur(80px);
}
/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/
@media (max-width: 418px) {
    .flow2-content .box-content{
        display: inline !important;
    }
}
@media (max-width: 1400px) {
    .opper-sec .card{
        height: 320px;
    }
}
@media (max-width: 1200px) {
    .services-circle .c-rotate-container{
        width: 230px;
        height: 230px;
    }
    .services-circle .item{
        --circle-radius: calc(230px / 2);
    }
    .services-circle .inner-circle .third img {
        width: 70px;
    }
    .services-circle .arrow{
        padding-left: 12px;
    }
    .sdk-con{
        background-size: 50% 88%;
    }
    .opper-sec img{
        margin-top: 10px;
    }
    .opper-sec .card{
        padding: 20px;
        height: 299px;
    }
    .opper-sec h3 {
        width: 100%;
    }
    .sdk-con {
        padding: 0px;
        padding-top: 80px;
    }
}
@media (max-width: 992px){
    .flow-content .consumer-app img{
        height: 295px;
    }
    .flow-content .native-app img {
        height: 315px;
    }
    .services-circle .arrow{
        padding-left: 0px;
    }
    .services-circle .c-rotate-container{
        width: 190px;
        height: 190px;
    }
    .services-circle .item{
        --circle-radius: calc(190px / 2);
    }
    .services-circle .inner-circle .third img {
        width: 55px;
    }
    /* .sdk-con{
        padding: 80px 0px;
    } */
    .opper-sec img {
        margin-top: 30px;
    }
    .opper-sec .card{
        height: 260px;
        min-height: 260px;
    }
    .flow2-content .box{
        width: 180px;
    }
}
@media (max-width: 767px) {
    .flow-content .consumer-app img{
        height: 285px;
    }
    .flow-content .native-app img {
        height: 295px;
    }
    .flow2-content .box{
        margin: 0px 5px 22px;
    }
    .flow2-content .box-content{
        display: flex;
    }
    .flow-content .native-app img{
        padding: 0;
    }
    .services-circle .c-rotate-container{
        width: 280px;
        height: 280px;
    }
    .services-circle .item{
        --circle-radius: calc(280px / 2);
        /* --circle-icon-sz: 30px; */
        /* --circle-icon-sm-img: 18px; */
    }
    .services-circle .inner-circle .third img {
        width: 60px;
    }
    .services-circle{
        min-height: 470px;
    }
    .services-circle .arrow{
        height: 250px;
        width: 900px;
        transform: translate(-50%, -50%) rotate(90deg);
        top: 0px;
    }
    /* .sdk-con{
        padding: 100px 0px;
    } */
}
@media (max-width: 576px){
    /* .opper-sec img {
        margin-top: 30px;
    } */
}