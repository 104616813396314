/*--------------------------------------------------------------
# Home Mobile Animation
--------------------------------------------------------------*/
.banner .img-mobile{
    /* width: 100%; */
    height: 541px;
    /* width: 293px; */
    width: 241px;
    filter: drop-shadow(2px 4px 6px black);
}
.banner .heading{
    padding-top: 100px;
}
.banner .headline{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.mobile-ani-sec {
    position: relative;
    /* transform: rotate(17deg); */
    display: flex;
    /* overflow: hidden; */
}
.mobile-ani-sec .display-mob{
    transform: rotate(17deg);
}
.mobile-ani-sec .squ-box{
    border: 1px solid #3293FF;
    /* width: 71px;
    height: 84px; */
    width: 81px;
    height: 90px;
    border-radius: 8px;
    position: absolute;
    background: black;
    padding: 12px;
    transform: rotate(17deg);
    transform: translateX(-50%) skew(-14deg);
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    -webkit-animation: box-float 9s ease-in-out infinite;
          animation: box-float 9s ease-in-out infinite;
    /* filter: drop-shadow(2px 4px 6px black); */
    /* box-shadow:
    1px 0px 0 0px #3293FF,
    -1px 0 28px 0 rgba(34, 33, 81, 0.01),
    28px 28px 28px 0 rgba(34, 33, 81, 0.25); */
}
/*--------------------------------------------------------------
# Square Box Animation
--------------------------------------------------------------*/
@-webkit-keyframes box-float {
    0% {
      transform: translatey(0px) translateX(-50%) skew(-18deg);
    }
    50% {
      transform: translatey(-50px) translateX(-50%) skew(-14deg);
    }
    100% {
      transform: translatey(0px) translateX(-50%) skew(-18deg);
    }
  }
  
  @keyframes box-float {
    0% {
      transform: translatey(0px) translateX(-50%) skew(-18deg);
    }
    50% {
      transform: translatey(-50px) translateX(-50%) skew(-14deg);
    }
    100% {
      transform: translatey(0px) translateX(-50%) skew(-18deg);
    }
  }
  @-webkit-keyframes box-float-reverse {
    0% {
      transform: translatey(0px) translateX(-50%) skew(-18deg);
    }
    50% {
        transform: translatey(50px) translateX(-50%) skew(-14deg);
    }
    100% {
      transform: translatey(0px) translateX(-50%) skew(-18deg);
    }
  }
  
  @keyframes box-float-reverse {
    0% {
      transform: translatey(0px) translateX(-50%) skew(-18deg);
    }
    50% {
      transform: translatey(50px) translateX(-50%) skew(-14deg);
    }
    100% {
      transform: translatey(0px) translateX(-50%) skew(-18deg);
    }
  }
.mobile-ani-sec .squ-box img{
    width: 32px;
    height: 32px;
    margin-bottom: 10px;
}
.mobile-ani-sec .squ-box span{
    font-size: 11px;
    font-weight: 400;
    color: white;
    /* margin-top: 12px; */
}
.mobile-ani-sec .squ-1 {
    /* left: 0;
    top: 0; */
    /* transform: rotate(12deg); */
    left: 5px;
    top: 138px;
}
.mobile-ani-sec .squ-2 {
    right: -75px;
    top: 96px;
    /* transform: rotate(7deg); */
    -webkit-animation: box-float-reverse 9s ease-in-out infinite;
    animation: box-float-reverse 9s ease-in-out infinite;
}
.mobile-ani-sec .squ-3 {
    left: -54px;
    bottom: 103px;
    /* transform: rotate(12deg); */
}
.mobile-ani-sec .squ-4 {
    right: -16px;
    bottom: 132px;
    /* transform: rotate(10deg); */
    -webkit-animation: box-float-reverse 9s ease-in-out infinite;
    animation: box-float-reverse 9s ease-in-out infinite;
}
.mobile-ani-sec .square-bg {
    /* width: 345px; */
    /* width: 300px; */
    /* height: 400px; */
    position: relative;
    text-align: center;
    display: flex;
    /* width: 306px; */
    width: 278px;
}
/*--------------------------------------------------------------
# Goal
--------------------------------------------------------------*/
.goal-content{
    border: 2px solid #2D81E0;
    border-width: 2px 0px;
    padding: 24px 0px;
    text-align: center;
    position: relative;
}
.goal-content h2{
    font-family: "Playfair Display", serif;
    padding-top: 0px;
    padding-bottom: 4px;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
}
.goal-content .content{
    margin-bottom: 0px;
    font-size: 22px;
    font-weight: 500;
    line-height: 32px;
}
.goal-content .oval{
    /* content:""; */
    position: absolute;
    height: 350px;
    width: 58%;
    background: linear-gradient(180deg, #2D81E0 0%, #F9FAFC 60%);
    border-radius: 50%;
    bottom: 9px;
    z-index: -1;
    filter: blur(130px);
backdrop-filter: blur(16px);
}
/*--------------------------------------------------------------
# Partner
--------------------------------------------------------------*/
/* .partner{
    padding-top: 65px;
} */
.partner .p-container{
    border-radius: 20px;
    background: linear-gradient(113.51deg, #fcfcfc, #f7fbff);
    border: 1px solid var(--color-lightsteelblue-100);
    box-sizing: border-box;
    overflow: hidden;
    /* padding: 16px 20px 50px; */
    box-shadow: none;
    margin: 0px 0px 28px;
    background: #FAFAFA;
    position: relative;
    /* padding: var(--padding-base) var(--padding-16xl) var(--padding-31xl); */
}
.partner h3{
    font-size: 33px;
    margin-bottom: 12px;
}
.partner p{
    font-size: 16px;
    color: var(--color-lightText);
    margin-bottom: 24px;
    min-height: 75px;
}
.partner .content{
    padding: 34px 0px 30px 34px;
    position: relative;
}
.partner img{
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    /* width: auto; */
    height: auto;
}
.partner .img-content{
    /* height: 269px; */
    justify-content: center;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}
/* .partner .partner-img-first{
    align-self: stretch;
    height: 200.9px;
    position: relative;
}
.partner .partner-img-last{
    height: 170px;
    width: 255.4px;
    flex-direction: column;
    padding: 6px 0 0;
    box-sizing: border-box;
    min-width: 255.4px;
    flex-shrink: 0;
    position: relative;
}
.partner .partner-img-last img{
    align-self: stretch;
    position: absolute;
}
.partner .partner-img-first img.first{
    position: absolute;
    top: 66px;
    left: 0;
    width: 255.4px;
    height: 113.5px;
}
.partner .partner-img-first img.second{
    position: absolute;
    top: 44px;
    left: 56px;
    width: 98px;
    height: 156.9px;
    z-index: 1;
}
.partner .partner-img-first img.third{
    position: absolute;
    top: 0;
    left: 64px;
    width: 44.6px;
    height: 62.6px;
    object-fit: contain;
    z-index: 2;
} */
.partner .circle-pnk,
.partner .circle-blu{
    background: var(--bg-pink);
    filter: blur(167px);
    width: 250px;
    height: 250px;
    position: absolute;
    transform: translate(-10%, 30%);
}
.partner .circle-blu{
    right: -89px;
}
@media screen and (min-width: 576px){
    .partner .p-container{
        height: 269px;
    }
}
/* @media screen and (max-width: 576px){
    .partner .p-container{
        padding: 20px 12px;
    }
} */
/*--------------------------------------------------------------
# Banner Video/gif
--------------------------------------------------------------*/
.mod-video .modal-content{
    background-color: #ffffff00;
    border: 0;
    margin-top: 67px;
}
.iframe-vd-cont iframe{
    width: 100%;
    height: 400px;
}
.video-con img{
    width: 75%;
}
/*--------------------------------------------------------------
# Feature
--------------------------------------------------------------*/
.feature .card{
    /* box-shadow: 0 1px 22.1px rgba(224, 224, 224, 0.31); */
    border-radius: 20px;
    /* background-color: white; */
    background: #fcfcfc;
    /* border: 1px solid var(--color-whitesmoke-300); */
    overflow: hidden;
    /* box-shadow: 0 1px 31.2px rgba(45, 129, 224, 0.3); */
    width: 100%;
    box-shadow: none;
}
/* .feature .analytic{
    background: #fcfcfc;
} */
/* .feature .analytic:hover{
    border: 1px solid #2d81e0;
} */
.feature .integration,
.feature .contract,
.feature .ai,
.feature .analytic{
    /* position: relative; */
    /* border: 1px solid #2d81e0; */
    /* border: 1px solid #0B82E6; */
    border: 1px solid #a8d1f4;
    /* background-size: contain; */
    /* -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out; */
    /* transition: all 800ms ease; */
    /* -webkit-transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    transition: all 0.8s ease;
    background: url(../../images/feature/contract-1.png) center center no-repeat; */
    /* width: 100%; */
    /* height: 100%; */
}
.feature .integration,
.feature .contract{
    margin-bottom: 28px;
}
/* .feature .integration img:first-child, */
/* .feature .ai img:first-child, */
/* .feature .analytic img:first-child, */
.feature .contract .contract-img-sec,
/* .feature .ai .img-content img:first-child, */
/* .feature .ai .img-content img:last-child */
.feature .img-content
{
    height: 175px;
}
.feature .integration img,
.feature .ai img,
.feature .analytic img
/* .feature .contract .contract-img-sec, */
/* .feature .ai .img-content img:first-child, */
/* .feature .ai .img-content img:last-child */
{
    /* height: 150px; */
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.feature .contract .contract-img-sec{
    width: 75%;
    /* float: right; */
    align-self: end;
    background: white;
    border: 1px solid #0B82E6;
    border-width: 0px 0px 1px 1px;
    border-radius: 0px 0px 0px 20px;
    padding: 0px 4px 4px 21px;
}
.feature .contract .contract-img-sec img:first-child{
    width: 100%;
    padding-right: 16px;
}
.feature .contract .contract-img-sec img:last-child{
    float: right;
    height: 60px;
}
.feature .analytic .img-content{
    /* width: 85%; */
    align-self: end;
    z-index: 2;
}
#ai-circle-blu{
    transform: translateX(0%) !important;
    width: 75% !important;
    height: 64px !important;
    margin: 0px auto;
    position: absolute;
}
.feature .analytic .img-content:hover~#ana-circle-blu,
.feature .ai .img-content:hover~#ai-circle-blu{
    width: 261px;
    height: 75px;
    position: absolute;
    /* background: #0076ff; */
    left: 0px;
    right: 0px;
    top: 14px;
    transform: translateX(43%);
    filter: blur(76px);
    /* backdrop-filter: blur(6px); */
    z-index: 1;
    background: #8DC0EB;
}
.feature .contract .contract-img-sec:hover{
    /* box-shadow: 0 1px 31.2px #2d81e04d; */
    box-shadow: 0 1px 31.2px #c5dbf3;
}
.feature .ai .img-content{
    position: relative;
    z-index: 2;
}
.feature .ai .img-content img:first-child{
    width: 100%;
    position: absolute;
}
.feature .ai .img-content .img-last{
    width: 100%;
    position: relative;
}
/* .image-hover {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 1s ease-out;
  }
  .image-hover:hover {
    opacity: 1;
    transform: scale(2,2);
    animation: createBox 800ms;
  }
  @keyframes createBox {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  } */
/* #feature .contract img { */
    /* width: 100%;
     -webkit-transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    transition: all 0.8s ease; */
  /* } */
/* .feature .contract img:hover { */
    /* background-image: url(../../images/feature/contract-2.png); */
    /* transform: scale(2,2); */
/* } */
/* .feature .ai img{
    margin-top: -45%;
} */
/* .feature .analytic img{
    transition: transform 0.3s ease;
    transform: translateX(0px);
} */
/* .feature .analytic img:hover{ */
    /* transition: all .25s ease-out; */
    /* transform: translateX(10px) !important; */
    /* padding-left:-10px; */
/* } */
/* .feature .contract img,
.feature .payment img{
    margin-bottom: -7%;
    margin-top: -24%;
    transform: scale(1.15, 1);
} */
/* .feature .analytic img{
    margin-top: -30%;
}
.feature .integration img{
    margin-top: -37%;
} */
/* .feature .tagline h3{
    color: var(--color-blue);
    justify-content: center;
    align-items: center;
    display: flex;
    height: 125px;
} */
/* .feature .tagline{
    width: 100%;
} */
.feature h3{
    font-size: 22px;
    /* padding: 20px 30px; */
    /* text-align: center; */
    /* padding: 30px 0px; */
}
.feature h2{
    padding-bottom: 76px;
}
.feature .content{
    padding: 20px 30px;
    /* text-align: center; */
    /* padding: 30px 0px; */
}
.feature p{
    color: var(--color-lightText);
    font-size: 16px;
}
.feature .content-lg  {
    /* padding-right: 120px; */
    padding-right: 258px;
}
.feature .content-sm{
    padding-right: 32px;
}
/* .feature .analytic h3{
    padding-bottom: 21px;
    position: absolute;
    z-index: 9;
    width: 100%;
}
.feature .integration h3,
.feature .contract h3,
.feature .ai h3{
    position: absolute;
    z-index: 9;
    width: 100%;
} */
/*--------------------------------------------------------------
# Why SuperGryd
--------------------------------------------------------------*/
/* .super-detail{
    background-color: var(--color-blue);
    color: white;
    padding: 100px;
    margin-bottom: 130px;
    position: relative;
    margin-top: 33px;
}
.super-detail p{
    font-weight: 600;
    font-size: 34px;
    margin-bottom: 0px;
    line-height: 1.2;
}
.super-detail .first-img,
.super-detail .last-img{
    width: 180px;
    position: absolute;
}
.super-detail .first-img{
    top: 0;
    left: 0;
}
.super-detail .last-img{
    bottom: 0;
    right: 0;
    rotate: 180deg;
} */
/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/
.circle-rotate{
    position: relative;
    overflow: hidden;
    --circle-radius: 233px;
    --circle-icon-sz: 60px;
    --circle-icon-img: 30px;
    --circle-icon-sm-img: 25px;
}
.circle-rotate .text-small-c{
    padding: 10px 90px 0px;
}
.circle-rotate h2{
    padding-bottom: 5px;
}
.circle-rotate button{
    margin-bottom: 40px;
}
.c-rotate-container{
    margin: 0px auto;
}
.circle-rotate .c-rotate-container{
    width: 466px;
    height: 466px;
}
.circle-rotate .circle-icon{
    position: relative;
    --n-icon:6;   /* number of item */
    --d-icon:35s; /* duration */
    /* width: 466px;
    height: 466px; */
    width: 100%;
    height: 100%;
    margin: 40px auto;
    border: 1px solid #1e6ed26b;
    display:grid;
    /* grid-template-columns:30px; */
    /* grid-template-rows:30px; */
    place-content: center;
    border-radius: 50%;
}
.circle-rotate .item {
    grid-area:1/1;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    background: #f00;
    animation: spin-icon var(--d-icon) linear infinite; 
    transform:rotate(0) translate(var(--circle-radius)) rotate(0);
    background: rgb(250,252,255);
    background: radial-gradient(circle, rgba(250,252,255,1) 0%, rgba(235,245,255,1) 100%);
    height: var(--circle-icon-sz);
    width: var(--circle-icon-sz);
    -webkit-box-shadow: #1d1d1d2e 0 0 29px;
    -moz-box-shadow: #1d1d1d2e 0 0 29px;
    box-shadow: #1d1d1d2e 0 0 29px;
    border: 2px solid #ffffff;
    align-content: center;
  }
  @keyframes spin-icon {
    100% {
      transform:rotate(1turn) translate(var(--circle-radius)) rotate(-1turn);
    }
  }
  @-webkit-keyframes spin-icon {
    100% {
        transform:rotate(1turn) translate(var(--circle-radius)) rotate(-1turn);
    }
  }
  .circle-rotate .item img{
    width: var(--circle-icon-img);
    /* height: 30px; */
  }
  .circle-rotate .item .icon-sm{
    width: var(--circle-icon-sm-img);
  }
  .circle-rotate .item:nth-child(1) {animation-delay:calc(-0*var(--d-icon)/var(--n-icon))}
  .circle-rotate .item:nth-child(2) {animation-delay:calc(-1*var(--d-icon)/var(--n-icon))}
  .circle-rotate .item:nth-child(3) {animation-delay:calc(-2*var(--d-icon)/var(--n-icon))}
  .circle-rotate .item:nth-child(4) {animation-delay:calc(-3*var(--d-icon)/var(--n-icon))}
  .circle-rotate .item:nth-child(5) {animation-delay:calc(-4*var(--d-icon)/var(--n-icon))}
  .circle-rotate .item:nth-child(6) {animation-delay:calc(-5*var(--d-icon)/var(--n-icon))}
  /*.item:nth-child(N) {animation-delay:calc(-(N - 1)*var(--d)/var(--n))}*/
  .circle-rotate .inner-circle{
    position: absolute;
    /* background: antiquewhite; */
    text-align: center;
    width: 100%;
    height: 100%;
    align-content: center;
  }
  .circle-rotate .inner-circle .first{
    /* width: 372px;
    height: 372px; */
    width: 80%;
    height: 80%;
    border: 1px solid #FAE3E2;
    box-shadow: inset 0 1px 51px #77b0ef33;
    backdrop-filter: blur(14px);
  }
  .circle-rotate .inner-circle .second{
    /* width: 272px;
    height: 272px; */
    width: 74%;
    height: 74%;
    border: 1px solid #1e6ed27a;
    box-shadow: 0 1px 70px #FFD1CC;
    /* box-shadow: inset 0 1px 9px #77B0EF; */
    backdrop-filter: blur(56px);
  }
  .circle-rotate .inner-circle .third{
    /* width: 186px;
    height: 186px; */
    width: 66%;
    height: 66%;
    /* border: 1px solid #2D81E0; */
    border: 1px solid #2d81e0cc;
    background: white;
    box-shadow: 0 1px 100px #9fc5ef;
  }
  .circle-rotate .inner-circle .third img{
    /* width: 100px; */
    width: 90px;
  }
  .circle-rotate .square-one,
  .circle-rotate .square-two{
    width: 200px;
    height: 518px;
    position: absolute;
  }
  .circle-rotate .square-one{
    top: 36%;
    transform: translate(-45%, -50%) rotate(18deg);
    /* position: relative; */
    /* box-shadow: 171px 76px 47px #ebeef6, 0px 60px 110px #fbe9e9;
    color: #fbe9e9;
    background: radial-gradient(circle, rgba(34, 193, 195, 0.30024509803921573) 0%, rgba(253, 187, 45, 0.3534663865546218) 100%); */
  }
  .circle-rotate .square-one .circle-top,
  .circle-rotate .square-one .circle-bottom,
  .circle-rotate .square-two .circle-top,
  .circle-rotate .square-two .circle-bottom{
    width: 315px;
    height: 318px;
    backdrop-filter: blur(-2px);
  }
  .circle-rotate .square-one .circle-top{
    transform: translateX(-40%);
  }
  .circle-rotate .square-one .circle-top,
  .circle-rotate .square-two .circle-top{
    /* background: #FFC3BD; */
    background: #f6e1ec;
    filter: blur(50px);
  }
  .circle-rotate .square-one .circle-bottom,
  .circle-rotate .square-two .circle-bottom{
    /* height: 300px; */
    /* background: #5BA9FF; */
    background: #c9dff8;
    filter: blur(100px);
    transform: translate(-37%, -39%);
    /* transform: translateX(-50%); */
  }
  .circle-rotate .square-two{
    width: 248px;
    /* transform: translate(-45%, -50%) rotate(26deg); */
    bottom: 15%;
    /* transform: translate(-24%, -50%) rotate(26deg); */
    right: -105px;
    transform: rotate(20deg);
    /* transform-origin: 0 0 0;*/
  }
  .circle-rotate .square-two .circle-bottom{
    transform: translate(4%, -37%);
  }
  /* .services-circle .item{
    transform: rotate(0) translate(var(--circle-radius)) rotate(0);
  } */
/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/
/* @media screen and (min-width: 1400px){
    .feature .analytic img {
        height: 255px;
    }
} */
/* @media (min-width: 576px) and (max-width: 756px) {
    .feature h3{
        padding: 20px 0px;
    }
} */
/* @media (min-width: 576px) and (max-width: 656px) {
    .feature .tagline h3{
        padding: 10px 0px;
        height: 64px;
    }
    .feature h3{
        font-size: 16px;
    }
} */
/* @media (min-width: 420px) and (max-width: 576px) {
    .feature h3 {
        font-size: 20px;
    }
}
@media (min-width: 1200px) and (max-width: 1400px) {
    .feature .analytic img {
        height: 225px;
    }
} */
@media (min-width: 768px) and (max-width: 992px) {
    /* .feature .contract .contract-img-sec img:last-child {
        height: 56px;
    } */
}
@media (max-width: 1200px){
    /* .feature .tagline h3{
        padding: 20px 0px;
        height: 74px;
    } */
    /* .feature h3{
        font-size: 18px;
    } */
    .feature .content-lg {
        padding-right: 120px;
    }
    .circle-rotate .text-small-c {
        padding: 10px 40px 0px;
    }
}
@media (max-width: 992px) {
    .banner .img-mobile{
        width: 75%;
        height: auto;
    }
    .mobile-ani-sec .squ-box{
        width: 61px;
        height: 70px;
        padding: 10px 7px;
    }
    .mobile-ani-sec .squ-box img {
        width: 25px;
        height: 25px;
        margin-bottom: 6px;
    }
    .mobile-ani-sec .squ-box span {
        font-size: 9px;
    }
    .mobile-ani-sec .squ-1{
        left: 19%;
        top: 110px;
    }
    .mobile-ani-sec .squ-2 {
        right: -19%;
        top: 75px;
    }
    .mobile-ani-sec .squ-3 {
        left: 5px;
        bottom: 90px;
    }
    .mobile-ani-sec .squ-4 {
        right: -1%;
        bottom: 126px;
    }
    .feature .contract .contract-img-sec img:last-child {
        height: 47px;
    }
    .feature .content-sm,
    .feature .content-lg{
        padding-right: 0px;
    }
    .feature .card{
        height: 278px;
    }
}
@media (max-width: 768px) {
    .banner .img-mobile{
        width: 75%;
    }
    .mobile-ani-sec{
        margin-top: 35px;
    }
    /* .mobile-ani-sec .squ-box{
        width: 61px;
        height: 70px;
        padding: 10px 7px;
    }
    .mobile-ani-sec .squ-box img {
        width: 25px;
        height: 25px;
        margin-bottom: 6px;
    }
    .mobile-ani-sec .squ-box span {
        font-size: 9px;
    } */
    .mobile-ani-sec .squ-1{
        left: 15%;
        top: 110px;
    }
    .mobile-ani-sec .squ-2 {
        right: -29%;
        top: 75px;
    }
    .mobile-ani-sec .squ-3 {
        left: -6px;
        bottom: 90px;
    }
    .mobile-ani-sec .squ-4 {
        right: -10%;
        bottom: 126px;
    }
    .feature .analytic{
        margin-bottom: 28px;
    }
    .feature .card {
        height: auto;
    }
    .feature .card p {
        margin-bottom: 0px;
    }
    .feature .contract .contract-img-sec img:last-child {
        height: 60px;
    }
    /* .feature .contract .contract-img-sec img:first-child {
        padding-right: 77px;
    } */
    .title {
        font-size: 35px;
    }
}
@media screen and (max-width: 576px){
    .feature .contract .contract-img-sec img:first-child{
        padding-right: 0px;
    }
    .banner .img-mobile{
        width: 65%;
    }
    .mobile-ani-sec {
        margin-top: 20px;
    }
    .mobile-ani-sec .squ-1 {
        left: 22%;
        top: 114px;
    }
    .mobile-ani-sec .squ-2 {
        right: -12%;
        top: 70px;
    }
    .mobile-ani-sec .squ-3 {
        left: 8%;
        bottom: 80px
    }
    .mobile-ani-sec .squ-4 {
        right: 4%;
        bottom: 120px;
    }
    .goal-content .content {
        font-size: 16px;
        line-height: 22px;
    }
    .feature h2 {
        padding-bottom: 25px;
    }
    /* .feature .content {
        padding: 12px;
    } */
    /* .feature .integration img:first-child,
    .feature .ai img:first-child,
    .feature .analytic img:first-child,
    .feature .contract .contract-img-sec {
        height: 100px;
    } */
    /* .feature .contract .contract-img-sec img:first-child {
        width: 70%;
    } */
    .iframe-vd-cont iframe{
        height: 300px;
    }
    .video-con img{
        width: 100%;
    }
    /* .feature .analytic{
        border: 1px solid #2d81e0;
    } */
    .feature .integration,
    .feature .contract,
    .feature .ai,
    .feature .analytic{
        margin-bottom: 28px;
    }
    /* .super-detail{
        margin-bottom: 65px;
        padding: 112px 0px;
    }
    .super-detail p{
        font-size: 28px;
    }
    .super-detail .first-img,
    .super-detail .last-img{
        width: 145px;
    } */
    .circle-rotate{
        --circle-radius: calc(80vw / 2);
        /* --circle-icon-sz: 60px;
        --circle-icon-img: 30px;
        --circle-icon-sm-img: 25px; */
    }
    .circle-rotate .c-rotate-container {
        width: 80vw;
        height: 80vw;
    }
    .circle-rotate .square-one {
        top: 46%;
    }
    .circle-rotate .square-two {
        bottom: 20%;
    }
    .partner .content {
        padding: 25px 155px 0px 17px;
    }
    .partner .img-content {
        margin-top: -47px;
    }
    .partner .circle-pnk,
    .partner .circle-blu{
        width: 200px;
        height: 200px;
        transform: translate(-12%, -17%);
    }
}
@media screen and (max-width: 400px){
    .iframe-vd-cont iframe{
        height: 235px;
    }
    .banner .img-mobile {
        width: 50%;
    }
    .mobile-ani-sec .squ-box{
        width: 56px;
        height: 64px;
        padding: 8px 5px;
    }
    .mobile-ani-sec .squ-box img {
        width: 23px;
        height: 23px;
    }
    .mobile-ani-sec .squ-box span {
        font-size: 8px;
        display: grid;
    }
    .mobile-ani-sec .squ-1 {
        left: 26%;
        top: 26%;
    }
    .mobile-ani-sec .squ-2 {
        right: -3%;
        top: 12%;
    }
    .mobile-ani-sec .squ-3 {
        left: 16%;
        bottom: 60px
    }
    .mobile-ani-sec .squ-4 {
        right: 8%;
        bottom: 29%;
    }
    .partner .content {
        padding: 25px 135px 0px 17px;
    }
}
/* .feature .card img{ */
    /* transform: scaleY(1.3); */
    /* transform-origin: bottom; */
/* } */
/* @media screen and (min-width: 768px){ */
    /* .feature .contract,
    .feature .analytic{
        height: 293px;
    }
    .feature .payment,
    .feature .integration{
        height: 350px;
    } */
    /* .feature .tagline{
        box-shadow: 0 1px 31.2px rgba(45, 129, 224, 0.3);
    } */
    /* .feature .ai{
        box-shadow: 0 1px 31.2px rgba(45, 129, 224, 0.3);
        height: 526px;
    } */
    /* .feature .tagline h3{
        height: 114px;
    }
} */