/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
:root{
    --color-gray: #1f1f1f;
    /* --color-blue: #0B82E6; */
    --color-blue: #0b82e6;
    --color-lightskyblue: #7ac3ff;
    --color-gray-700: rgba(255, 255, 255, 0.85);
    --color-lavender: #cbe7ff;
    --color-black: #0c0d0d;
    --color-lightsteelblue-100: #b5d3f4;
    --color-whitesmoke-300: #ebebeb;
    --color-whitesmoke-100: #f4f4f4;
    --color-lightText: #5D5D5D;
    --bg-pink: #FFC3BD;
    --bg-blue: #C3DDFB;
     /*--theme-blue2: #0f326b;
    --blue: #007bff;
    --blue-3: #1454a2;
    --blue-4: #0a376d;
    --theme-primary-blue: rgb(0, 86, 171);
    --theme-dark-blue: rgb(10, 55, 109);
    --theme-dark-grey: rgb(78, 90, 91);
    --theme-dark-grey2: rgb(128, 128, 127);
    --theme-purple: #942093; */
}
body {
    /* background: #fff; */
    background: #fcfcfc;
    color: #2f3138;
    /* font-family: "Open Sans", sans-serif; */
    font-family: "Roboto", Open Sans;
    /* font-weight: 600; */
    font-size: 16px;
    text-transform: none;
    line-height: 1.5;
}
p{
    line-height: 24px;
}
a {
    /* color: var(--theme-red); */
    color: #1e72bd;
    text-decoration: none;
    transition: 0.5s;
  }
li {
    list-style: none;
}
/*--------------------------------------------------------------
# Loader
--------------------------------------------------------------*/
.preloader{
    opacity:    0.5; 
    /* background: #000;  */
    width:      100%;
    height:     100%; 
    z-index:    9999;
    top:        0; 
    left:       0; 
    position:   fixed;
}
.pre-loader{
    position: absolute;
    top: 50%;
    margin-top: -20px;
    left: 50%;
    margin-left: -20px;
    width: 35px;
    aspect-ratio: 1;
    --preloader:no-repeat linear-gradient(#046D8B 0 0);
    background:
        var(--preloader) 0 0,
        var(--preloader) 100% 0,
        var(--preloader) 100% 100%,
        var(--preloader) 0 100%;
    animation: 
        l2-1 2s infinite,
        l2-2 2s infinite;
}
@keyframes l2-1 {
    0%   {background-size: 0    4px,4px 0   ,0    4px,4px 0   }
    12.5%{background-size: 100% 4px,4px 0   ,0    4px,4px 0   }
    25%  {background-size: 100% 4px,4px 100%,0    4px,4px 0   }
    37.5%{background-size: 100% 4px,4px 100%,100% 4px,4px 0   }
    45%,
    55%  {background-size: 100% 4px,4px 100%,100% 4px,4px 100%}
    62.5%{background-size: 0    4px,4px 100%,100% 4px,4px 100%}
    75%  {background-size: 0    4px,4px 0   ,100% 4px,4px 100%}
    87.5%{background-size: 0    4px,4px 0   ,0    4px,4px 100%}
    100% {background-size: 0    4px,4px 0   ,0    4px,4px 0   }
}
@keyframes l2-2 {
    0%,49.9%{background-position: 0 0   ,100% 0   ,100% 100%,0 100%}
    50%,100%{background-position: 100% 0,100% 100%,0    100%,0 0   }
}
/*--------------------------------------------------------------
# Bootstrap
--------------------------------------------------------------*/
.btn{
    font-size: 14px;
    padding: 10px 16px;
    line-height: 16px;
}
.btn-outline-primary:hover{
    color: white !important;
}
.btn-outline-primary{
    color: var(--color-blue);
    border: 1.5px solid var(--color-blue);
    background-color: #fcfcfc;
    font-weight: 500;
    font-size: 14px;
}
.btn:disabled{
    background: #b6b3b3;
    /* border-color: grey; */
    border-color: #dee2e6;
}
h2{
    font-size: 40px;
    z-index: 1;
    position: relative;
}
h1, h2{
    font-weight: 700;
    line-height: 1.2;
}
/* h2 span, */
h1 .highlight,
.highlight{
    color: var(--color-blue);
}
h2{
    padding: 33px 0;
}
h3{
    font-weight: 500;
    margin: 0px;
}
section{
    padding-bottom: 130px;
}
@media screen and (max-width: 576px){
    section{
        padding-bottom: 30px;
    }
    body{
        font-size: 14px;
    }
}
.opa-3{
    opacity: 0.3;
}
.opa-6{
    opacity: 0.6;
}
.opa-2{
    opacity: 0.2;
}
.list-num ol li::marker {
    font-size: 26px;
}
.list-num li{
    list-style: decimal;
}
.col-white{
    color: white;
}
.text-small-c{
    font-size: 16px;
    font-weight: 400;
    color: #5E5E5E;
}
.border-circle{
    border-radius: 50%;
}
.mt-btm-header{
    margin-top: 75px;
}
/*--------------------------------------------------------------
# Loader
--------------------------------------------------------------*/
.loader-con img{
    height: 28px;
}
/*--------------------------------------------------------------
# Bootstrap
--------------------------------------------------------------*/
.card{
    border-radius: 15px;
    background-color: white;
    border: 1px solid var(--color-whitesmoke-300);
    overflow: hidden;
    box-shadow: 0 0 16px rgba(0, 0, 0, .08);
    /* box-shadow: 0 13px 12px -6px #646161a8; */
    /* box-shadow: 0 1px 31.2px rgba(45, 129, 224, 0.3); */
}
.btn{
    border-radius: 60px;
}
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.title{
    font-size: 49px;
    font-weight: 600;
}
.header{
    background-color: white;
    box-shadow: 0 0px 3px 0 rgba(32, 33, 36, 0.28);
    border-bottom: 0.1px solid #E9E9E9;
}
header .logo img,
footer .logo img{
    height: 52px;
}
#header.header-scrolled{
    height: 75px;
    z-index: 9999;
    position: fixed;
    top: 0;
    width: 100%;
}
header nav ul.main-menu {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0px;
    margin: 0;
    --webkit-backdrop-filter: blur(94.6px);
    backdrop-filter: blur(94.6px);
    background-color: var(--color-gray-700);
    /* border: 1px solid var(--color-lightskyblue); */
    /* box-sizing: border-box; */
    justify-content: center;
    /* border: 1px solid var(--color-lightskyblue); */
    /* border-radius: 10px; */
    /* padding: var(--padding-2xs); */
    /* gap: var(--gap-13xl); */
    /* text-align: center; */
    /* font-size: var(--roboto-text-1-semibold-size); */
    /* color: var(--color-gray-500); */
    /* font-family: var(--roboto-text-1-semibold); */
}
header nav ul.main-menu li a {
    color: var(--color-gray);
    display: inline-block;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 500;
    margin: 0 10px;
    outline: none;
    padding: 12px 10px;
    position: relative;
}
header nav ul.main-menu li a::after,
header nav ul.main-menu li a:hover::after{
    transform-origin: bottom center;
}
header nav ul.main-menu li a:hover::after {
    transform: scaleX(1);
}  
header nav ul.main-menu li a:hover,
header nav ul.main-menu li a.active{
    color: var(--color-blue);
}
header nav ul.main-menu li a.active:after,
header nav ul.main-menu li a:hover:after,
header nav ul.main-menu li a:active>a:after{
    background-color: var(--color-blue);
    bottom: 8px;
    content: "";
    height: 1.5px;
    /* left: 50%; */
    margin-left: -35px;
    position: absolute;
    transition: all .2s;
    width: calc(100% - 20px);
    right: 0;
    /* bottom: -5px; */
    left: 0;
    margin: auto;
}
header nav ul.main-menu li a::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1.5px;
    bottom: 0;
    left: 0;
    background-color: var(--color-blue);
    transition: transform 0.25s ease-out;
}
header nav{
    width: fit-content;
    margin: 0px auto;
    /* border: 1px solid var(--color-lightskyblue); */
    /* background-color: var(--color-gray-700); */
    /* border-radius: 10px; */
}
.banner{
    margin-top: 75px;
}
/* .banner{
    background: url(../images/grid.svg) 50% center no-repeat;
    background-size: cover !important;
    overflow: hidden;
    position: relative;
    padding-bottom: 65px;
} */
/* header .contact{ */
    /* border: 1.5px solid; */
    /* --webkit-backdrop-filter: blur(94.6px);
    backdrop-filter: blur(94.6px); */
    /* background: white; */
/* } */
/* header .half-oval {  */
    /* margin: 100px auto 0px; */
    /* border-radius: 50% 50% 0 0/ 100% 100% 0px 0px; */
    /* position: relative; */
    /* border-radius: 0% 0% 50% 50% / 0% 0% 100% 100%; */
    /* width: calc(100% - 20px); */
    /* background-color: var(--color-lavender-200); */
    /* padding-bottom: 165px;
    width: 100%; */
/* } */
header .menu {
    /* padding-bottom: 165px; */
    width: 100%;
}
/* header .half-oval:before{
    content: "";
    background-color: var(--color-lavender-200);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    --webkit-filter: blur(205px);
    filter: blur(205px);
    border-radius: 0% 0% 50% 50% / 0% 0% 100% 100%;
    margin: 0px 50px;
} */
header .logo{
    margin: 14px 0px;
 }
 header nav{
    margin: 14px auto;
}
 
header .contact
{
    margin-top: 18px;
    font-size: 14px;
    width: max-content;
    height: 40px;
    width: 133px;
    /* box-shadow: 0 1px 31.2px rgba(45, 129, 224, 0.3); */
    box-shadow:  0 0px 37.5px #0b82e647;
    backdrop-filter: blur(94.6px);
    --webkit-backdrop-filter: blur(94.6px);
    background: black;
    border-radius: 60px;
}
/*--------------------------------------------------------------
# Mobile Header
--------------------------------------------------------------*/
header .mobile-nav-toggle{
    cursor: pointer;
    display: none;
    font-size: 28px;
    /* line-height: 0; */
    transition: .5s;
    padding-top: 19px;
}
.navbar-mobile {
    background: #000000e6;
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    transition: .3s;
    z-index: 999;
}
.navbar-mobile ul {
    background-color: #fff !important;
    bottom: 15px;
    display: block !important;
    left: 15px;
    overflow-y: auto;
    padding: 10px 0;
    position: absolute;
    right: 15px;
    top: 55px;
    transition: .3s;
}
/*--------------------------------------------------------------
# Heading and Title
--------------------------------------------------------------*/
.heading h1 span{
    white-space: nowrap;
}
.heading h1{
    /* line-height: 60px;
    font-size: 50px; */
    line-height: 70px;
    font-size: 60px;
    position: relative;
    /* padding-top: 120px; */
}
/* .heading .arrow{
    width: 85px;
    height: 145px;
    vertical-align: text-top;
    margin-top: -81px;
    position: relative;
    right: 384px;
}
.heading .arrow-fxd{
    height: 145px;
    margin-right: 18%;
    margin-top: -11px;
    position: relative;
} */
.heading .sub-heading{
    color: var(--color-lightText);
    font-size: 18px;
    margin-top: 18px;
    margin-bottom: 0px;
    position: relative;
    width: 65%;
}
.curl{
    font-family: "Playfair Display", serif;
    /* font-optical-sizing: auto; */
    font-style: italic;
    font-weight: 500;
}
.title .curl{
    font-weight: 500;
}
.title-gap{
    /* margin-top: 150px; */
    margin-top: 75px;
}
.title-tagline{
    font-size: 18px;
    color: #0D0D0D;
}
.btn-cstm,
.heading .btn,
.contact-con .btn{
    font-size: 14px;
    height: 40px;
    /* position: absolute; */
    margin-top: 28px;
}
.content-lgt{
    color: var(--color-lightText);
    font-size: 16px;
}
.content-lgt-md{
    color: var(--color-lightText);
    font-size: 18px;
}
/* .heading h1:after{ */
    /* content:"";
    background: url(../images/arrow.png) no-repeat;
    top: 96px;
    right: 170px;
    width: 85px;
    height: 145px;
    vertical-align: text-top;
    position: absolute;
    background-size: contain; */
    /* background-size: 100% 100%;
    position: absolute;
    content: "";
    width: 86px;
    height: 86px;
    background-repeat: no-repeat;
    background-position: 75% 40%; */
/* } */
/* .video-con iframe{
    width: 100%;
    border-radius: 10px;
} */
/* .video-con .iframe-container{
    border-radius: 10px;
    border: 1px solid var(--color-blue);
    padding: 5px;
    padding-bottom: 0px;
    display:inline-block;
    position: relative;
    width: 100%;
    box-shadow: 0 1px 12.2px rgba(45, 129, 224, 0.3);
} */
/* .video-con .btn-outline-primary:hover img{
    filter: invert(1);
    filter: invert(1) sepia(1) saturate(30) hue-rotate(102deg);
} */
/* .video-con .btn-cont-1,
.video-con .btn-cont-2{
    position: absolute;
    width: 100%;
    padding: 12px 0px;
}
.video-con .btn-cont-1 .btn,
.video-con .btn-cont-2 .btn{
    height: 48px;
    color: var(--color-black);
    font-weight: 500;
    font-size: 20px;
}
.video-con .btn-cont-1{
    top: 0px;
}
.video-con .btn-cont-2{
    bottom: 0px;
}
.video-con .btn-1{
    left: -130px;
} */
/* .video-con .btn-2{
    left: 28px;
}
.video-con .btn-4{
    right: -84px;
}
.video-con .btn-3{
    left: -44px;
}
.video-con .btn-1,
.video-con .btn-2,
.video-con .btn-3,
.video-con .btn-4{
    position: relative;
} */
/* .video-con .btn-1 .btn,
.video-con .btn-2 .btn,
.video-con .btn-3 .btn,
.video-con .btn-4 .btn, */
.heading .btn{
    box-shadow: 0 1px 31.2px rgb(45 129 224 / 54%);
}
/*--------------------------------------------------------------
# Background Color
--------------------------------------------------------------*/
#circle1,
.circle-sm-bg-pink{
    background: var(--bg-pink);
    /* background: linear-gradient(206deg, #FFC3BD 0%, #5BA9FF 100%); */
    /* backdrop-filter: blur(6px); */
    /* filter: blur(2px); */
    border-radius: 50%;
    width: 100%;
    position: absolute;
    height: 75%;
    /* box-shadow: 16px -17px 51.2px #FFC3BD; */
    filter: blur(141px);
    border-radius: 50%;
    width: 86%;
    position: absolute;
    height: 77%;
    /* box-shadow: 16px -17px 51.2px #FFC3BD; */
    /* background: radial-gradient(100% 100% at 50% 50%, #FFC3BD 0, #FFC3BD 40%, #FFC3BD 50%); */
    right: 0;
    top: -12px;
    filter: blur(80px);
backdrop-filter: blur(16px);
    
}
#circle2,
.circle-sm-bg-blue{
    background: #5BA9FF;
    /* background: linear-gradient(206deg, #FFC3BD 0%, #5BA9FF 100%); */
    /* backdrop-filter: blur(6px); */
    border-radius: 50%;
    width: 100%;
    position: absolute;
    height: 75%;
    /* box-shadow: 16px -17px 51.2px #5BA9FF; */
    filter: blur(141px);
    width: 86%;
    height: 77%;
    bottom: -35px;
    left: -26px;
    filter: blur(100px);
/* backdrop-filter: blur(16px); */
    /* -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -ms-filter: blur(2px);
  -o-filter: blur(2px);
  filter: blur(2px);  */
}
.circle-blue,
.circle-pink{
    border-radius: 25%;
    width: 100px;
    height: 100px;
    background: var(--bg-blue);
    height: 465px;
    /* width: 578px; */
    width: 35%;
    border-radius: 0px 0px 311px 0;
    /* transform: translate(6%, -50%); */
    position: absolute;
    /* filter: blur(150px); */
    filter: blur(167px);
    z-index: -1;
}
.circle-pink{
    /* transform: rotate(180deg); */
    transform: scaleX(-1);
    right: 0px;
    background: var(--bg-pink);
    filter: blur(167px);
}
.seller-sec .circle-blue,
.seller-sec .circle-pink{
    height: 302px;
    width: 45%;
}
.bg-c-gradient{
    filter: blur(150px);
    z-index: -1;
    position: absolute;
    border-radius: 50%;
}
/*--------------------------------------------------------------
# Change Word Animation
--------------------------------------------------------------*/
.change-text .word-re {
    position: absolute;
    display: flex;
    opacity: 0;
}
.change-text .word-re .letter {
    transform-origin: center center 25px;
}
.change-text .word-re .letter.out {
    transform: rotateX(90deg);
    transition: 0.32s cubic-bezier(0.6, 0, 0.7, 0.2);
}
.change-text .word-re .letter.in {
    transition: 0.38s ease;
}
.change-text .word-re .letter.behind {
    transform: rotateX(-90deg);
}
/*--------------------------------------------------------------
# FAQ
--------------------------------------------------------------*/
.faq{
    padding-bottom: 65px;
}
.faq h3{
    padding: 0px;
    font-size: 16px;
}
.faq h3 button{
    font-weight: 500;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}
/* .faq button.collapsed{
    border-radius: 23px;
} */
.faq .accordion-item{
    border-radius: 12px;
}
.faq .accordion-button:focus{
    box-shadow: none;
}
.faq .accordion-button:not(.collapsed){
    /* color: var(--color-blue);
    background-color: #e3eefb; */
    color: #ffffff;
    background-color: #000000;
}
.faq button.collapsed{
    border-radius: 12px !important;
    background: #F1F1F1;
}
.faq .accordion-button:not(.collapsed):after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}
/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
/* .contact-con{
    position: relative;
    background: url(../images/bg/network.png) 50% center no-repeat;
    background-size: cover !important;
    overflow: hidden;
    position: relative;
    padding: 102px 0px;
    background-color: var(--color-blue);
    border-radius: 20px;
} */
.contact-con .btn-outline-primary:hover{
    background-color: #fcfcfc;
    color: var(--color-blue) !important;
    font-size: 15px;
}
.contact-con img{
    position: absolute;
    bottom: 0;
    height: 35%;
}
.contact-con h2{
    color: white;
}
.contact-con p{
    font-size: 20px;
    /* margin-bottom: 0px; */
    color: #EBEBEB;
}
.contact-con .btn{
    width: 160px;
    height: 46px;
}
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
footer{
    /* margin-top: 70px; */
    background-color: var(--color-whitesmoke-100);
    /* padding: 50px 0px; */
    padding: 50px 0px 16px
}
footer ul{
    padding-left: 0px;
}
footer ul li a,
footer .social-head{
    color: #5E5E5E;
}
footer .email{
    color: black;
    font-weight: 500;
}
footer .social{
    display: flex;
}
footer .social i{
    font-size: 25px;
    color: #969696;
}
footer .copyright{
    color: #5E5E5E;
    text-align: center;
    margin-bottom: 0px;
}
footer .social li:not(:last-child){
    margin-right: 16px;
}
footer ul li,
footer .social-head{
    margin-bottom: 12px;
    font-weight: 500;
}
footer .social-head{
    font-size: 17px;
}
footer .location p,
footer .location a{
    color: #363636;
}
/* footer .btn{
    height: 100%;
    width: 100%;
    background-color: #DEEEFF;
    border: none;
    color: black;
    padding: 0px 28px;
} */
/* footer .btn .arrow{
    font-size: 18px;
} */
/* footer .btn .email{
    font-size: 16px;
    padding-left: 28px;
} */
/* footer .btn-outline-primary:hover img{
    filter:  brightness(0) invert(1);
} */

/*--------------------------------------------------------------
# Contact Form
--------------------------------------------------------------*/
.con-form-sec{
    /* background-color: #F4F4F4; */
    /* background-color: var(--color-blue); */
    background-color: #161717;
    /* padding-top: 65px;
    padding-bottom: 65px; */
    padding-top: 100px;
    padding-bottom: 100px;
    color: white;
}
.con-form-sec .cont-details{
    padding-left: 0;
    list-style-type: none;
    margin-top: 35px;
    margin-bottom: 0;
}
.con-form-sec .cont-details p{
    font-weight: 400;
    max-width: 300px;
    margin-bottom: 0;
    margin-top: 2px;
}
.con-form-sec .cont-details li{
    margin-bottom: 35px;
    display: flex;
}
.con-form-sec .cont-details li:hover .icon{
    /* background-color: #dcf8ff; */
    /* color: #4aa7be; */
    box-shadow: 0 1px 31.2px rgb(255 255 255);
}
.con-form-sec .cont-details span{
    color: #dcf8ff;
}
.con-form-sec .cont-details a{
    color: white;
}
.con-form-sec .cont-details .icon{
    width: 36px;
    height: 36px;
    /* background-color: #f8f9f8; */
    background-color: #5CACEE;
    border-radius: 5px;
    text-align: center;
    font-size: 40px;
    transition: .5s;
    margin-right: 20px;
    padding: 8px;
    align-content: center;
    justify-content: center;
    display: grid;
}
.con-form-sec h3{
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 800;
    line-height: 15px;
    color: #5CACEE;
}
.con-form-sec .cont-details i{
    /* color: var(--color-blue); */
    color: #181818;
    font-size: 18px;
}
.con-form-sec .cont-details .fa-phone-volume{
    font-size: 25px;
}
.con-form-sec form{
    background: #252525;
    box-shadow: 0 0 16px #111111;
    border: 1px solid #111111;
}
.con-form-sec form label{
    color: #FFFFFF;
}
.con-form-sec form input,
.con-form-sec form textarea{
    background-color: #343434;
    border: none;
    color: #e0dddd;
    margin-bottom: 30px;
    border-radius: 10px;
    padding: 18px;
}
.con-form-sec form input{
    height: 46px;
}
.con-form-sec .form-control:focus {
    color: #ffffff;
    background-color: #343434;
    /* border: 1px solid #ffffff; */
    box-shadow: 0 0 0 .1rem rgb(255 255 255 / 25%);
}
/* .con-form-sec input::-webkit-input-placeholder,
.con-form-sec textarea::-webkit-input-placeholder,
.con-form-sec input:-moz-placeholder,
.con-form-sec textarea:-moz-placeholder, */
.con-form-sec input::placeholder,
.con-form-sec textarea::placeholder{
  font-size: 16px;
  color: #B8B8B8;
  font-weight: normal;
}
.con-form-sec button{
    height: 46px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 17px;
    margin-top: 30px;
    margin-bottom: 5px;
}
/* .rc-anchor-dark{
    background: red !important;
} */
.con-form-sec .map-container button{
    border-radius: 60px;
    background: #0b1c2e;
    border-color: #195D96;
    color: white;
    font-size: 12px;
    padding: 5px 10px;
    font-weight: normal;
    height: auto;
    margin: 20px 20px 30px 0px;
 }
.con-form-sec .map-container{
    border-radius: 20px;
    border: 1px solid #747474;
    padding-bottom: 55px;
}
.con-form-sec .map-container img{
    width: 100%;
}
.address-popover p{
    margin-bottom: 0px;
}
/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# For Very Small Screen
--------------------------------------------------------------*/
/* @media (max-width: 320px) {
    h2.title{
        font-size: 24px;
    }
}
@media (max-width: 480px) {
    .partner h3{
        font-size: 20px;
        font-weight: 600;
    }
} */
@media (max-width: 1400px){
    .heading h1{
        font-size: 52px;
        line-height: 60px;
    }
    .heading .sub-heading{
        width: 75%;
    }
}
@media (max-width: 1200px){
    .heading h1{
        font-size: 40px;
        line-height: 50px;
    }
    .heading .sub-heading{
        width: 75%;
    }
}
@media (max-width: 992px) {
    section{
        padding-bottom: 100px;
    }
    .banner .heading {
        padding-top: 60px;
    }
    .heading h1{
        font-size: 35px;
        line-height: 45px;
    }
    .heading .sub-heading{
        width: 83%;
    }
    .title {
        font-size: 40px;
    }
}
@media (max-width: 768px) {
    header #navbar ul {
        display: none;
    }
    header .mobile-nav-toggle {
        display: block;
    }
    .headline{
        text-align: center;
    }
    .banner .heading {
        padding-top: 40px;
    }
    .heading h1,
    .heading .sub-heading{
        text-align: center;
    }
    .heading h1{
        font-size: 42px;
        line-height: 52px;
    }
    .heading h1 .highlight{
        width: 200px;
    }
    .heading .sub-heading{
        /* font-size: 16px; */
        margin-top: 5px;
        width: 68%;
        display: inline-block;
    }
    .con-form-sec {
        padding-top: 30px;
        padding-bottom: 70px;
    }
}
@media (max-width: 576px) {
    /* h2{
        padding: 0px;
    } */
    h3 {
        font-size: 22px !important;
    }
    h2.title {
        font-size: 39px;
        line-height: 44px;
        /* text-align: center; */
    }
    .heading h1 .highlight{
        width: 120px;
    }
    .heading .sub-heading{
        font-size: 16px;
        width: 84%;
        /* display: inline-block; */
    }
    .heading h1{
        font-size: 30px;
        line-height: 40px;
    }
    .heading .btn{
        margin-top: 16px;
        font-size: 13px;
        height: 33px;
        line-height: 12px;
    }
    #circle1, .circle-sm-bg-pink{
        top: 20px;
    }
    /* .video-con img{
        width: 100%;
    } */
    section {
        padding-bottom: 65px;
    }
    .partner .partner-img-first{
        top: -63px;
        height: 55px;
    }
    .partner .partner-img-first img.first,
    .partner .partner-img-last img{
        right: -92px;
        left: auto;
    }
    .partner .partner-img-first img.second{
        right: 56px;
        left: auto;
    }
    .partner .partner-img-first img.third{
        display: none;
    }
    .partner .partner-img-last{
        top: -30px;
        position: relative;
        height: 70px;
        width: 100%;
    }
    .heading h1 span {
        white-space: normal;
    }
}
/* @media (min-width: 320px) and (max-width: 480px) {
    h2.title{
        font-size: 28px;
    }
} */
/* @media (min-width: 320px) and (max-width: 576px) {
    .partner h2 span{
        display: block;
    }
} */
@media (max-width: 402px) {
    .heading h1 {
        font-size: 20px;
        line-height: 24px;
    }
    .heading .sub-heading {
        font-size: 14px;
        line-height: 20px;
        width: 75%;
    }
}
/* @media (min-width: 1200px) and (max-width: 1400px) {
    .heading .arrow{
        right: 173px;
    }
} */
/* @media (min-width: 992px) and (max-width: 1200px) {
    .heading h1{
        font-size: 40px;
    }
} */
