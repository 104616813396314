/*--------------------------------------------------------------
# About Top Section
--------------------------------------------------------------*/
.about{
    padding-bottom: 100px;
}
.about .title{
    font-size: 60px;
}
.about .title-tagline{
    line-height: 48px;
}
.about .about-bg{
    margin-top: 60px;
    width: 100%;
    border-radius: 20px;
}
.about .circle-blue{
    background: var(--bg-pink);
    height: 190px;
    width: 24%;
}
.about .circle-pnk{
    width: 150px;
    height: 150px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 31%);
    height: 100px;
    width: 200px;
    border-radius: 0px 0px 150px 150px;
    /* background: var(--bg-pink); */
    background: #f95646;
    filter: blur(130px);
    backdrop-filter: blur(16px);
    z-index: -1;
}
/*--------------------------------------------------------------
# Mission
--------------------------------------------------------------*/
/* .mission-con iframe{
    width: 100%;
} */
.mission-con .content{
    /* font-size: 18px; */
    font-size: 23px;
    line-height: 40px;
    margin-top: 1rem;
}
.mission-con img{
    /* height: 352px; */
    width: 100%;
    border-radius: 20px;
}
/*--------------------------------------------------------------
# Founder
--------------------------------------------------------------*/
.founder-con .cover{
    height: 205px;
    width: 205px;
    position: relative;
    border-radius: 50%;
    object-fit: cover;
}
.founder-con .designation p{
    font-size: 20px;
    font-weight: 300;
}
.founder-con .overlay{
    position: absolute;
    top: 145px;
    /* right: 23%; */
    right: 26%;
    border-radius: 50%;
    background-color: #fcfcfc;
    width: 69px;
    height: 69px;
    z-index: 1;
    justify-content: center;
    display: flex;
    align-items: center;
}
.founder-con .cover-content{
    position: relative;
}
@media screen and (max-width: 1200px){
    .mission-con .content{
        font-size: 18px;
        line-height: 1.5;
    }
}
@media (min-width: 1200px) and (max-width: 1400px) {
    .mission-con .content{
        font-size: 20px;
        line-height: 1.5;
    }
}
@media (min-width: 576px) and (max-width: 665px) {
    .founder-con .cover{
        width: 100%;
        height: auto;
    }
}
/*--------------------------------------------------------------
# Privacy Page
--------------------------------------------------------------*/
.privacy ol li{
    margin-bottom: 30px;
}
@media (max-width: 1400px) {
    .about .heading h1 {
        line-height: 69px;
    }
}
@media (max-width: 767px) {
    .about .heading h1{
        text-align: left;
        font-size: 42px;
        line-height: 52px;
    }
    .about .about-bg {
        margin-top: 30px;
        height: 165px;
    }
    .about {
        padding-bottom: 30px;
    }
}
